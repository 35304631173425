import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/image/timetable/timetable-cloud.png'
import _imports_1 from '@/assets/image/timetable/timetable-cloud_subject.png'
import _imports_2 from '@/assets/image/timetable/timetable-conference.png'


const _hoisted_1 = { class: "timetable-index" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_teacher_information2 = _resolveComponent("teacher-information2")!
  const _component_logout_icon = _resolveComponent("logout-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_teacher_information2),
    _createVNode(_component_logout_icon),
    _createElementVNode("img", {
      src: _imports_0,
      alt: "left",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onRouterTo('MyTimetable')))
    }),
    _createElementVNode("img", {
      src: _imports_1,
      alt: "right",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRouterTo('CourseSelect')))
    }),
    _createElementVNode("img", {
      src: _imports_2,
      alt: "center",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onRouterTo('ConferenceClassList')))
    })
  ]))
}