import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "TeacherInformation2" }
const _hoisted_2 = { class: "welcome" }
const _hoisted_3 = { class: "welcome-text" }


export default /*@__PURE__*/_defineComponent({
  __name: 'TeacherInformation2',
  setup(__props) {

const storage = localStorage.getItem('user')
const userInfo = JSON.parse(storage || '')


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(userInfo)?.name) + "老师，欢迎使用大卫云课堂！ ", 1)
    ])
  ]))
}
}

})